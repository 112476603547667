import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, addDoc, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './icons'; // Certifique-se de que o caminho está correto

const Questionarios = () => {
  const [questionarios, setQuestionarios] = useState([]);

  useEffect(() => {
    const fetchQuestionarios = async () => {
      const querySnapshot = await getDocs(collection(db, 'questionarios'));
      const questionariosList = querySnapshot.docs.map((document) => ({
        id: document.id,
        ...document.data(),
      }));
      setQuestionarios(questionariosList);
    };

    fetchQuestionarios();
  }, []);

  const adicionarQuestionario = async () => {
    const novoQuestionario = { nome: "Novo Questionário", ativo: true }; // Exemplo, ajuste conforme necessário
    const docRef = await addDoc(collection(db, 'questionarios'), novoQuestionario);
    setQuestionarios([...questionarios, { id: docRef.id, ...novoQuestionario }]);
  };

  const excluirQuestionario = async (id) => {
    await deleteDoc(doc(db, 'questionarios', id));
    setQuestionarios(questionarios.filter(q => q.id !== id));
  };

  const toggleAtivo = async (id, ativo) => {
    await updateDoc(doc(db, 'questionarios', id), { ativo: !ativo });
    setQuestionarios(questionarios.map(q => (q.id === id ? { ...q, ativo: !ativo } : q)));
  };

  return (
    <div className="container">
      <button style={{ backgroundColor: 'green', color: 'white' }} onClick={adicionarQuestionario}>
        Adicionar Novo Questionário
      </button>
      <h1>Lista de Questionários</h1>
      <ul>
        {questionarios.map(q => (
          <li key={q.id}>
            {q.nome} - {q.ativo ? 'Ativo' : 'Inativo'}
            <Link to={`/questionario/${q.id}`}>
              <button>
                <FontAwesomeIcon icon="eye" /> Visualizar
              </button>
            </Link>
            <Link to={`/editar-questionario/${q.id}`}>
              <button style={{ backgroundColor: 'blue', color: 'white' }} >
                <FontAwesomeIcon icon="edit" /> Editar
              </button>
            </Link>
            <button style={{ backgroundColor: 'orange', color: 'white' }} onClick={() => toggleAtivo(q.id, q.ativo)}>
              {q.ativo ? 'Desativar' : 'Ativar'}
            </button>
            <button style={{ backgroundColor: 'red', color: 'white' }} onClick={() => excluirQuestionario(q.id)}>
              <FontAwesomeIcon icon="trash" /> Excluir
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Questionarios;
