import React, { useState, useEffect } from 'react';
import './App.css';
import { db } from './firebaseConfig'; // Certifique-se de que o caminho está correto
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function Perguntas() {
  const navigate = useNavigate();

  const loadQuestions = () => {
    const savedQuestions = JSON.parse(localStorage.getItem('questions'));
    return savedQuestions || { nome: '', questions: [{ id: 1, question: '', type: 'text', options: [''], required: false }] };
  };

  const [questionario, setQuestionario] = useState(loadQuestions());

  useEffect(() => {
    // Salvar as perguntas no localStorage sempre que houver uma mudança
    localStorage.setItem('questions', JSON.stringify(questionario));
  }, [questionario]);

  const handleNameChange = (value) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      nome: value,
    }));
  };

  const handleQuestionChange = (id, value) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: prevQuestionario.questions.map((q) =>
        q.id === id ? { ...q, question: value } : q
      ),
    }));
  };

  const handleTypeChange = (id, value) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: prevQuestionario.questions.map((q) =>
        q.id === id ? { ...q, type: value, options: value === 'text' ? [] : [''] } : q
      ),
    }));
  };

  const handleOptionChange = (qId, index, value) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: prevQuestionario.questions.map((q) =>
        q.id === qId
          ? {
              ...q,
              options: q.options.map((opt, idx) =>
                idx === index ? value : opt
              ),
            }
          : q
      ),
    }));
  };

  const handleRequiredChange = (id, value) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: prevQuestionario.questions.map((q) =>
        q.id === id ? { ...q, required: value } : q
      ),
    }));
  };

  const addQuestion = () => {
    const newId = questionario.questions && questionario.questions.length
      ? questionario.questions[questionario.questions.length - 1].id + 1
      : 1;
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: [
        ...(prevQuestionario.questions || []),
        { id: newId, question: '', type: 'text', options: [], required: false },
      ],
    }));
  };

  const removeQuestion = (id) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: prevQuestionario.questions.filter((q) => q.id !== id),
    }));
  };

  const addOption = (qId) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: prevQuestionario.questions.map((q) =>
        q.id === qId ? { ...q, options: [...q.options, ''] } : q
      ),
    }));
  };

  const removeOption = (qId, index) => {
    setQuestionario((prevQuestionario) => ({
      ...prevQuestionario,
      questions: prevQuestionario.questions.map((q) =>
        q.id === qId
          ? { ...q, options: q.options.filter((_, idx) => idx !== index) }
          : q
      ),
    }));
  };

  const duplicateQuestion = (id) => {
    const questionToDuplicate = questionario.questions.find(q => q.id === id);
    if (questionToDuplicate) {
      const newId = questionario.questions[questionario.questions.length - 1].id + 1;
      const duplicatedQuestion = { ...questionToDuplicate, id: newId };
      setQuestionario((prevQuestionario) => ({
        ...prevQuestionario,
        questions: [...prevQuestionario.questions, duplicatedQuestion],
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Enviado: ', questionario);

    // Salvar no Firestore
    try {
      const docRef = await addDoc(collection(db, 'questionarios'), questionario);
      console.log('Documento escrito com ID: ', docRef.id);
      // Vai pra rota inicial
      navigate('/');
    } catch (e) {
      console.error('Erro ao adicionar documento: ', e);
    }
  };

  return (
    <div className="renderQ">
      <h1>Questionário</h1>
      <form onSubmit={handleSubmit}>
        <div className="question-block">
          <label>
            Nome do Questionário:
            <input
              type="text"
              value={questionario.nome}
              onChange={(e) => handleNameChange(e.target.value)}
              required
            />
          </label>
        </div>
        {questionario.questions && questionario.questions.map((q) => (
          <div key={q.id} className="question-block">
            <label>
              Pergunta:
              <input
                type="text"
                value={q.question}
                onChange={(e) => handleQuestionChange(q.id, e.target.value)}
                required={q.required}
              />
            </label>
            <label>
              Tipo:
              <select
                value={q.type}
                onChange={(e) => handleTypeChange(q.id, e.target.value)}
              >
                <option value="text">Texto</option>
                <option value="radio">Escolha Múltipla</option>
              </select>
            </label>
            {q.type === 'text' ? (
              <input type="text" placeholder="Resposta do texto" />
            ) : (
              <div>
                {q.options.map((option, index) => (
                  <div key={index} className="option-row">
                    <input
                      type="text"
                      value={option}
                      onChange={(e) => handleOptionChange(q.id, index, e.target.value)}
                    />
                    <button type="button" onClick={() => removeOption(q.id, index)}>
                      X
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => addOption(q.id)}
                  style={{ backgroundColor: 'green', color: 'white' }}
                >
                  Adicionar Opção
                </button>
              </div>
            )}
            <label style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}> 
              Pergunta Obrigatória?
              <input
                type="checkbox"
                checked={q.required}
                onChange={(e) => handleRequiredChange(q.id, e.target.checked)}
              /> Sim
            </label>
            <button type="button" onClick={() => removeQuestion(q.id)}>
              Remover Pergunta
            </button>
            <button type="button" onClick={() => duplicateQuestion(q.id)}>
              Duplicar Pergunta
            </button>
          </div>
        ))}
        <button type="button" onClick={addQuestion} style={{ backgroundColor: 'green', color: 'white' }}>
          Adicionar Pergunta
        </button>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
}

export default Perguntas;