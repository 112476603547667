
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDoT5lV7aQadXEddKu_nQ026rpbngwaFUs",
  authDomain: "tabulare-bb269.firebaseapp.com",
  projectId: "tabulare-bb269",
  storageBucket: "tabulare-bb269.appspot.com",
  messagingSenderId: "1016623848181",
  appId: "1:1016623848181:web:49b8d317fb05ddc19c43bc"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };