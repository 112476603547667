import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';

const ResponderQuestionario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [questionario, setQuestionario] = useState(null);
  const [respostas, setRespostas] = useState([]);

  useEffect(() => {
    const fetchQuestionario = async () => {
      const docRef = doc(db, 'questionarios', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestionario(docSnap.data());
        setRespostas(docSnap.data().questions.map(() => ""));
      } else {
        console.log('No such document!');
      }
    };

    fetchQuestionario();
  }, [id]);

  const handleChange = (index, value) => {
    const newRespostas = [...respostas];
    newRespostas[index] = value;
    setRespostas(newRespostas);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'results'), {
        //questionarioNome: questionario.nome,
        questionarioId: id,
        answers: questionario.questions.map((q, index) => ({
          answers: q.question,
          option: respostas[index],
        })),
      });
      navigate('/resultados');
    } catch (error) {
      console.error('Erro ao salvar respostas: ', error);
    }
  };

  if (!questionario) {
    return <p>Carregando questionário...</p>;
  }

  return (
    <div className="container">
      <h1>Responder Questionário: {questionario.name}</h1>
      <form onSubmit={handleSubmit}>
        {questionario.questions.map((q, index) => (
          <div key={index} className="question-block">
            <p>{q.question}</p>
            {q.type === 'text' ? (
              <input
                type="text"
                value={respostas[index]}
                onChange={(e) => handleChange(index, e.target.value)}
                required
              />
            ) : (
              <div>
                {q.options.map((option, oIndex) => (
                  <div key={oIndex}>
                    <input
                      type="radio"
                      id={`q${index}_o${oIndex}`}
                      name={`q${index}`}
                      value={option}
                      checked={respostas[index] === option}
                      onChange={(e) => handleChange(index, option)}
                      required
                    />
                    <label htmlFor={`q${index}_o${oIndex}`}>{option}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        <button type="submit">Enviar Respostas</button>
      </form>
    </div>
  );
};

export default ResponderQuestionario;
