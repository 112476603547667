// Resultados.js
import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';

const ExportData = () => {
 

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const resultsCol = collection(db, 'results');
          const resultSnapshot = await getDocs(resultsCol);
          const resultList = resultSnapshot.docs.map(doc => doc.data());
          setData(resultList);
          setLoading(false);
        } catch (err) {
          console.log(Error(err));
          setLoading(false);
        }
        
      };
      fetchData();
    }, []);
  
    if (loading) {
      return <p>Loading...</p>;
    }
  
    if (error) {
      console.error('Erro ao buscar dados: ', error);
      return <p>Erro ao buscar dados</p>;
    }
  
    const extractQuestionAndAnswer = (data) => {
      // Mapear cada objeto do array de dados
      return data.map(item => {
        // Verificar se o objeto tem a chave 'answers'
        if (item.hasOwnProperty('answers')) {
          // Encontrar a opção de resposta marcada como verdadeira
          const trueAnswer = item.answers.find(answer => answer.checked === true);
          // Retornar objeto com a pergunta e a resposta verdadeira, ou null se não houver resposta verdadeira
          return { question: item.question, answer: trueAnswer ? trueAnswer.label : null };
        } else {
          // Se não houver a chave 'answers', retornar null para a resposta
          return { question: item.question, answer: null };
        }
      });
    };
  
    const formatDataForCSV1 = (data) => {
      return data.map(item => {
          const answers = JSON.parse(item[0]).answers;
          return answers.map(answer => [answer.question, ...answer.options].join(' - '));
      });
  };
  
    // Extrair pergunta e resposta verdadeira
    //const extractedData = extractQuestionAndAnswer(data);
    //const csvData = data.map(arr => [JSON.stringify(arr)]);
    //const csvData1 = formatDataForCSV(data.map(arr => [JSON.stringify(arr)]));
  
    
    const formatDataForCSV = (data) => {
      const records = [];
      data.forEach(entry => {
        const email = entry.email;
        const location = entry.location;
        const timestamp = entry.timestamp;
        const latitude = location ? location.latitude : '';
        const longitude = location ? location.longitude : '';
        let formatted = '';
        if (timestamp) {
          // Convertendo string para objeto Date se o timestamp estiver definido
          const date = new Date(timestamp);
          formatted = format(date, 'dd/MM/yyyy HH:mm');
        }
    
        entry.answers.forEach(answer => {
          const record = {
            email: email,
            latitude: latitude,
            longitude: longitude,
            date: formatted
          };
          answer.questions.forEach(question => {
            const q_text = question.question;
            const answers = question.options.join(', ');
            record[q_text] = answers;
          });
          records.push(record);
        });
      });
      return records;
    };

    const csvData = formatDataForCSV(data);

    const contador = data.length;
    return ( 
      <div>
        <CSVLink data={csvData} filename="data.csv">
          <button style={{ fontSize: '20px', padding: '10px 20px' }}>Baixar CSV</button>
        </CSVLink>
        <h2>Pesquisas Realizadas : {contador}</h2>
        {/* Exibir dados extraídos na tela */}
        {/*<h2>Dados Extraídos:</h2>
        <ul >
        questionario1
        <pre>{JSON.stringify(data[0].answers[0].question, null, 2)}</pre>
        <pre>{JSON.stringify(data[0].answers[0].options[0], null, 2)}</pre>
        <pre>{JSON.stringify(data[0].answers[1].question, null, 2)}</pre>
        <pre>{JSON.stringify(data[0].answers[1].options[0], null, 2)}</pre>
        </ul>
        <div>
        {/* Exibir Perguntas e Opções 
        <h3>Perguntas e Opções:</h3>
        {data.map((item, index) => (
          <div key={index}>
            {item.answers.map((answer, ansIndex) => (
              <div key={ansIndex}>
                <h3>Pergunta: {answer.question}</h3>
                <ul>
                  {answer.options.map((option, optIndex) => (
                    <li key={optIndex}>{option.label}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>*/}
      </div>
    );
  };

const Resultados = () => {
  return (
    <div className="container">
      <h1>Resultados</h1>
      <p>Exportar Dados para CSV</p>
          <ExportData />
    </div>
  );
};

export default Resultados;
