import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';

const VisualizarQuestionario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [questionario, setQuestionario] = useState(null);

  useEffect(() => {
    const fetchQuestionario = async () => {
      const docRef = doc(db, 'questionarios', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestionario(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchQuestionario();
  }, [id]);

  return (
    <div className="container">
      {questionario ? (
        <>
          <h1>{questionario.name}</h1>
          {questionario.questions.map((question, qIndex) => (
            <div key={qIndex}>
              <p>{question.question}</p>
              {question.type === 'text' ? (
                <input type="text" disabled placeholder="Resposta do texto" />
              ) : (
                <ul>
                  {question.options.map((option, oIndex) => (
                    <li key={oIndex}>{option}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </>
      ) : (
        <p>Carregando questionário...</p>
      )}
      <button onClick={() => navigate('/')} style={{ backgroundColor: 'blue', color: 'white' }}>
        Voltar
      </button>
    </div>
  );
};

export default VisualizarQuestionario;
