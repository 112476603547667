import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Questionarios from './Questionarios';
import Resultados from './Resultados';
import Perguntas from './Perguntas';
import VisualizarQuestionario from './VisualizarQuestionario';
import EditarQuestionario from './EditarQuestionario';
import ResponderQuestionario from './ResponderQuestionario';
import Login from './Login';
import './App.css'; // Importar o arquivo CSS para estilização

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <div className="app">
        <header>
          <div className="logo">
            <img src="logo.png" alt="Logo" />
          </div>
          <nav className="navbar">
            <ul>
              <li><Link to="/">Questionário</Link></li>
              <li><Link to="/resultados">Resultados</Link></li>
            </ul>
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            {isAuthenticated ? (
              <>
                <Route path="/" element={<Questionarios />} />
                <Route path="/resultados" element={<Resultados />} />
                <Route path="/criar-questionario" element={<Perguntas />} />  
                <Route path="/perguntas" element={<Perguntas/>} />
                <Route path="/questionario/:id" element={<VisualizarQuestionario />} />
                <Route path="/editar-questionario/:id" element={<EditarQuestionario />} />
                <Route path="/responder-questionario/:id" element={<ResponderQuestionario />}/>
              </>
            ) : (
              <Route path="*" element={<Navigate to="/login" />} />
            )}
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
